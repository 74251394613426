import React from "react"
import Hero from "../components/hero"
import PostLink from "../components/post-link"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import "../styles/index.css"
import { BurgerMenuIconAnimation } from "../constants/layoutStyle"
import BurgerMenu from "../components/burgerMenu";
import TopPageLayout from "../components/topPageLayout";

export default function Home({ data }) {
  return (
    <>
      <SEO />
      <TopPageLayout>
        <BurgerMenu iconAnination={BurgerMenuIconAnimation.Visible} />
        <div className="top-main">
          <Hero />
          <main className="main-area">
            {data.allContentfulPost.edges.map(edge => (
              <PostLink key={edge.node.slug} post={edge.node} />
            ))}
          </main>
        </div>
      </TopPageLayout>
    </>
  )
}
export const query = graphql`
  query allContentfulPost {
    allContentfulPost(
      filter: { node_locale: { eq: "ja" } }
      sort: { fields: [publicationDate], order: [DESC] }
    ) {
      edges {
        node {
          title
          indexImage {
            title
            gatsbyImageData
          }
          images {
            title
            gatsbyImageData
          }
          description {
            description
          }
          slug
          updatedAt(locale: "ja-JP", formatString: "YYYY年MM月DD日")
          publicationDate
        }
      }
    }
    allContentfulSiteLogos(filter: { node_locale: { eq: "ja" } }) {
      edges {
        node {
          heroImage {
            url
          }
        }
      }
    }
  }
`
